import React from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { useSpring } from '@react-spring/core'
import { a } from '@react-spring/web'
import { Helmet } from "react-helmet"
import Overlay from './Overlay'
import Scene from './Scene'

export default function App() {
  // This spring controls the background and the svg fill (text color)
  const [{ background, fill }, set] = useSpring({ background: '#f0f0f0', fill: '#202020' }, [])
  return (
    <>
      <Helmet>
        <title>Association BLOB</title>
        <meta name="description" content="Promouvoir les échanges inclusifs entre les citoyens à travers l'art, l'éducation, la culture et les nouvelles technologies, notamment l'intelligence artificielle et ce, au moyen d'outils et méthodes innovants et en faveur du développement durable" />
        <link rel="canonical" href="http://blob.style" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <a.main style={{ background }}>
        <Canvas className="canvas" dpr={[1, 2]}>
          <Scene setBg={set} />
          <OrbitControls enablePan={false} enableZoom={false} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2} />
        </Canvas>
        <Overlay fill={fill} />
      </a.main>
    </>
  )
}
