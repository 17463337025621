import React from 'react'
import { a } from '@react-spring/web'
// import { TypeAnimation } from 'react-type-animation';

{/* <TypeAnimation
            sequence={[
              'Échanges inclusifs',
              1000,
              'Art',
              1000,
              'Éducation',
              1000,
              'Culture',
              1000,
              'Nouvelles technologies',
              1000,
            ]}
            speed={50}
            style={{ background: 'transparent', fontSize: '2em', fontFamily:'Inter', fontWeight:'bold', fontSize:'48px', letterSpacing:'0em' }}
            repeat={Infinity}
          /> */}

export default function Overlay({ fill }) {
  // Just a Figma export, the fill is animated
  return (
    <div className="overlay">
      <a.svg viewBox="0 0 583 720" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path fill="#E8B059" d="M50.5 61h9v9h-9zM50.5 50.5h9v9h-9zM40 50.5h9v9h-9z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M61 40H50.5v9H61v10.5h9V40h-9z" fill="#E8B059" />
        <text className="small-text" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={10.5} fontWeight="bold" letterSpacing="-.02em">
          <tspan x={280} y={46.182} children="En construction" />
        </text>
        <text className="small-text" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={10.5} fontWeight="bold" letterSpacing="-.02em">
          <tspan x={392} y={46.182} children="NOBODY " />
          <tspan x={392} y={59.182} children="LEFT " />
          <tspan x={392} y={72.182} children="BEHIND" />
        </text>
        <text className="small-text" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={10.5} fontWeight={500} letterSpacing="0em">
          <tspan x={40} y={175.318} children="INNOVATIVE TOOLS AND METHODS " />
          <tspan x={40} y={188.318} children="CREATE SUCCESS STORIES" />
        </text>
        <text fill="#E8B059" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={52} fontWeight="bold" letterSpacing="0em">
          <tspan x={40} y={257.909} children={'Association BLOB \u2014'} />
        </text>
        <text style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={12} fontWeight="bold" letterSpacing="0em">
          <tspan x={40} y={270.909} />
        </text>
        <text style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={48} fontWeight="bold" letterSpacing="0em">
          <tspan x={40} y={321.909} children="Échanges inclusifs " />
          <tspan x={40} y={372.909} children="entre les citoyens. " />
          <tspan x={40} y={423.909} children="Art, éducation, " />
          <tspan x={40} y={474.909} children="culture, et nouvelles " />
          <tspan x={40} y={525.909} children="technologies. " />
          {/* <tspan x={40} y={576.909} children="" /> */}
        </text>

        <a className="contact" href="https://forms.gle/ceSWWLKmpC9DDp7f6" target="_blank">
          <text style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize={14} fontWeight={500} letterSpacing="0em">
            <tspan x={326} y={640.318} children="Contactez-nous" />
          </text>
        </a>
      </a.svg>
    </div>
  )
}
